import { ReactNode, useCallback } from 'react';
import { Security } from '@okta/okta-react';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import oktaAuth from '../../lib/oktaAuth';
import useNavigation from '../../hooks/useNavigation';

interface SecurityProviderProps {
    children: ReactNode;
}

const SecurityProvider = ({ children }: SecurityProviderProps) => {
    const { navigateToLogin, navigate } = useNavigation();

    /** redirect to login page if user is not authenticated on protected route */
    const customAuthHandler = useCallback(() => {
        navigateToLogin();
    }, [navigateToLogin]);

    /** returns the page back to the original url post login */
    const restoreOriginalUri = useCallback(
        (_oktaAuth: OktaAuth, originalUri: string) => {
            navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
        },
        [navigate],
    );

    return (
        <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
            {children}
        </Security>
    );
};

export default SecurityProvider;
