import { OktaAuth } from '@okta/okta-auth-js';

const appUrl = process.env.APP_URL || 'https://localhost:5173';
const oktaUrl = process.env.OKTA_URL;
const authorizationServer = process.env.AUTHORIZATION_SERVER || 'default';
const clientId = process.env.CLIENT_ID;

const oktaAuth = new OktaAuth({
    issuer: `${oktaUrl}/oauth2/${authorizationServer}`,
    clientId,
    redirectUri: `${appUrl}/login/callback`,
    postLogoutRedirectUri: `${appUrl}/logout`,
    cookies: {
        secure: true,
    },
    pkce: true,
    tokenManager: {
        storage: 'localStorage',
        secure: true,
    },
    transformAuthState: async (oktaAuth, authState) => {
        if (!authState.isAuthenticated) return authState;
        const user = await oktaAuth.token.getUserInfo();
        authState.user = user;
        return authState;
    },
});

export default oktaAuth;
