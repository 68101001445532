import React from 'react';
import { Link } from 'react-router-dom';
import { LuArrowRightCircle } from 'react-icons/lu';
import './ExploreButton.css';

const ExploreButton: React.FC = () => {
    return (
        <Link to="https://smartr.build/" className="explore-button">
            <span>Explore</span>
            <span className="explore-button-spacing weight-bold">SMARTR</span>
            <span className="explore-button-reg">®</span>
            <span className="explore-button-spacing">Products</span>
            <LuArrowRightCircle className="explore-button-spacing" />
        </Link>
    );
};

export default ExploreButton;
