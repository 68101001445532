import LoginLayout from '../../components/layout/LoginLayout';
import TenantEmptyScreen from '../../features/tenant-selection/components/TenantEmptyScreen';
import TenantSelectionForm from '../../features/tenant-selection/components/TenantSelectionForm';
import useAuth from '../../hooks/useAuth';

const TenantSelectionRoute = () => {
    const { tenants } = useAuth();

    if (tenants.length === 0) return <TenantEmptyScreen />;

    return (
        <LoginLayout header="Select tenant to manage">
            <TenantSelectionForm />
        </LoginLayout>
    );
};

export default TenantSelectionRoute;
