import { useEffect, useRef, useState } from 'react';
import { MdChevronRight } from 'react-icons/md';
import './Select.css';
import AccessibilitySelect from './AccessibilitySelect';

interface SelectOption {
    value: string;
    label: string;
}

interface SelectProps {
    options: SelectOption[];
    value: string;
    onChange: (newValue: string) => void;
    placeholder: string;
}

const Select = ({ options, value, onChange, placeholder }: SelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const selectInputRef = useRef<HTMLDivElement>(null);
    const optionRefs = useRef<(HTMLDivElement | null)[]>([]);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const toggleOpen = () => {
        setIsOpen((prev) => !prev);
    };

    const handleSelect = (newValue: string) => {
        onChange(newValue);
        setIsOpen(false);
        setHighlightedIndex(-1);
    };

    const selectedLabel = options.find((option) => option.value === value)?.label;

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!isOpen) return;

        if (event.key === 'ArrowDown') {
            setHighlightedIndex((prevIndex) => (prevIndex + 1) % options.length);
        } else if (event.key === 'ArrowUp') {
            setHighlightedIndex((prevIndex) => (prevIndex - 1 + options.length) % options.length);
        } else if (event.key === 'Enter' && highlightedIndex !== -1) {
            handleSelect(options[highlightedIndex].value);
        }
        event.preventDefault();
    };

    useEffect(() => {
        if (highlightedIndex !== -1 && isOpen) {
            const highlightedOption = optionRefs.current[highlightedIndex];
            if (highlightedOption) {
                highlightedOption.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
            }
        }
    }, [highlightedIndex, isOpen]);

    return (
        <div className="select" onKeyDown={handleKeyDown} ref={selectInputRef}>
            <AccessibilitySelect options={options} value={value} onChange={onChange} placeholder={placeholder} />

            {/* UI components shown to non accessibility user */}
            <div className="select-input" onClick={toggleOpen} tabIndex={0} data-testid="select-input" aria-hidden="true">
                {selectedLabel || <span className="select-input-placeholder">{placeholder}</span>}
                <MdChevronRight size="1.2rem" style={{ transform: `rotate(${isOpen ? 270 : 90}deg)` }} />
            </div>

            <div className={`select-options ${isOpen ? 'open' : ''}`} data-testid="select-options" ref={scrollContainerRef}>
                <div className="select-options-padding">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`select-option ${index === highlightedIndex ? 'highlighted' : ''}`}
                            ref={(el) => (optionRefs.current[index] = el)}
                            // comes before onBlur in the propagation order on clicks events get stolen by onBlur
                            onMouseDown={() => handleSelect(option.value)}
                        >
                            <MdChevronRight size="1.5rem" color="#34C39D" />
                            {option.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Select;
