import { useState } from 'react';
import { deleteStorageValue, setStorageValue } from '../utils/localStorage';
import { EMAIL_STORAGE_KEY } from '../utils/consts';

const useIsRemembered = (email: string) => {
    const [isRemembered, setIsRemembered] = useState(email !== '');

    const handleClickIsRemembered = () => {
        setIsRemembered((prev) => {
            const newIsRemembered = !prev;
            if (newIsRemembered) setStorageValue(EMAIL_STORAGE_KEY, email);
            else deleteStorageValue(EMAIL_STORAGE_KEY);
            return newIsRemembered;
        });
    };

    const onSubmit = () => {
        if (isRemembered) setStorageValue(EMAIL_STORAGE_KEY, email);
    };

    return {
        isRemembered,
        handleClickIsRemembered,
        onSubmit,
    };
};

export default useIsRemembered;
