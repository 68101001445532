import { FormEvent } from 'react';
import { MdOutlineArrowCircleRight } from 'react-icons/md';
import Button from '../../../components/ui/Button';
import ButtonSubtle from '../../../components/ui/ButtonSubtle';
import Checkbox from '../../../components/ui/Checkbox';
import TextInput from '../../../components/ui/TextInput';
import TextInputPassword from '../../../components/ui/TextInputPassword';
import TextInputSelected from '../../../components/ui/TextInputSelected';
import ButtonSecondary from '../../../components/ui/ButtonSecondary';
import ErrorMessage from '../../../components/ui/ErrorMessage';
import useLoginForm from '../hooks/useLoginForm';
import useIsRemembered from '../hooks/useIsRemembered';
import useAuth from '../../../hooks/useAuth';
import useMutation from '../../../hooks/useMutation';
import './LoginForm.css';

const LoginForm = () => {
    const { password, setPassword, email, setEmail, handleChangeEmail, handleForgottenPassword, isEmailSet, setIsEmailSet } = useLoginForm();
    const { isRemembered, handleClickIsRemembered, onSubmit } = useIsRemembered(email);
    const { handleSignIn, requiresOktaWebsite, handleRedirectToAuth } = useAuth();

    const { mutate, error, reset } = useMutation(handleSignIn);
    const { mutate: redirectAuthMutate, error: redirectAuthError } = useMutation(handleRedirectToAuth);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit();
        if (!isEmailSet) {
            const goToOkta = await requiresOktaWebsite(email);
            if (goToOkta) {
                await redirectAuthMutate(email);
                return;
            }
            setIsEmailSet(true);
        } else {
            await mutate(email, password);
        }
    };

    const handleSwitchAccount = () => {
        reset();
        handleChangeEmail();
    };

    return (
        <form onSubmit={(event: FormEvent<HTMLFormElement>) => void handleSubmit(event)} className="login-form">
            <ErrorMessage message={error?.message || redirectAuthError?.message} />

            {isEmailSet ? (
                <>
                    <TextInputSelected label="Email Address" type="email" name="email" value={email} onClickChange={handleSwitchAccount} />
                    <TextInputPassword name="password" value={password} onChange={setPassword} placeholder="Password" tabIndex={1} />
                </>
            ) : (
                <TextInput
                    label="Email Address"
                    type="email"
                    name="email"
                    value={email}
                    onChange={setEmail}
                    placeholder="Email Address"
                    tabIndex={0}
                />
            )}

            <ButtonSubtle label="Forgot Password?" onClick={handleForgottenPassword} />

            <Checkbox label="Remember me" name="remember-me" checked={isRemembered} onChange={handleClickIsRemembered} />

            <Button
                disabled={isEmailSet ? password === '' : email === ''}
                label={isEmailSet ? 'Continue' : 'Sign in'}
                iconComponent={<MdOutlineArrowCircleRight size="1.2rem" />}
                type="submit"
            />

            {isEmailSet && <ButtonSecondary label="Switch account" onClick={handleSwitchAccount} />}
        </form>
    );
};

export default LoginForm;
