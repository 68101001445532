import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import LoadingRoute from './LoadingRoute';

const AuthenticatedRoute: React.FC = () => {
    const { isLoading, isAuthenticated } = useAuth();
    if (isLoading) return <LoadingRoute />;
    return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default AuthenticatedRoute;
