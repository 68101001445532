import ButtonSecondary from '../../../components/ui/ButtonSecondary';
import ButtonSubtle from '../../../components/ui/ButtonSubtle';
import useNavigation from '../../../hooks/useNavigation';

const ForgottenPasswordFormButtons = () => {
    const { navigateToLogin } = useNavigation();
    return (
        <>
            {/* different button displayed dependant on screen size handled in css */}
            <ButtonSecondary label="Back to login" onClick={navigateToLogin} />
            <ButtonSubtle label="Back to login" onClick={navigateToLogin} />
        </>
    );
};

export default ForgottenPasswordFormButtons;
