import LoginLayout from '../../components/layout/LoginLayout';
import ForgottenPasswordForm from '../../features/login/components/ForgottenPasswordForm';

const ForgottenPasswordRoute = () => {
    return (
        <LoginLayout header="Forgotten password">
            <ForgottenPasswordForm />
        </LoginLayout>
    );
};

export default ForgottenPasswordRoute;
