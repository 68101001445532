import { getAccessToken } from '../features/dashboard/utils/getAccessToken';

interface FetcherOptions<B = unknown> {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    headers?: Record<string, string>;
    body?: B;
}

const fetcher = async <T = unknown, B = unknown>(url: string, options: FetcherOptions<B>): Promise<T> => {
    const { method, headers, body } = options;
    const accessToken = getAccessToken();

    if (!accessToken) {
        throw new Error('No access token found');
    }

    const fetchOptions: RequestInit = {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            ...headers,
        },
        body: body ? JSON.stringify(body) : undefined,
    };

    const response = await fetch(url, fetchOptions);

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json() as Promise<T>;
};

export default fetcher;
