import React from 'react';
import ModulesSection from './ModulesSection';
import useAuth from '../../../hooks/useAuth';
import UserBar from './UserBar';
import useUserData from '../hooks/useUserData';
import DashboardLoadingIndicator from './DashboardLoadingIndicator';
import DashboardHeroImage from './DashboardHeroImage';
import smartrLogo from '../../../assets/smartr-logo.webp';
import './Dashboard.css';

const Dashboard: React.FC = () => {
    const { user } = useAuth();
    const { userData, isLoading, error } = useUserData();
    const firstName = user?.firstName || '';

    if (isLoading) return <DashboardLoadingIndicator />;

    return (
        <>
            <UserBar />
            <div className="dashboard">
                <div className="dashboard-header">
                    <div className="dashboard-header-content">
                        <div className="dashboard-header-welcome-card">
                            <img src={smartrLogo} className="dashboard-header-smartr-logo" alt="SMARTR Logo" />
                            <h2>Welcome to the Cloud, {firstName}</h2>
                            <h4 className="dashboard-header-message">Manage your account, get 24/7 support and access SMARTR capability modules.</h4>
                        </div>
                        <DashboardHeroImage />
                    </div>
                </div>
                <ModulesSection userData={userData} error={error} />
            </div>
        </>
    );
};

export default Dashboard;
