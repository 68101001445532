import { ChangeEvent } from 'react';

interface SelectOption {
    value: string;
    label: string;
}

interface AccessibilitySelectProps {
    options: SelectOption[];
    value: string;
    placeholder: string;
    onChange: (newValue: string) => void;
}

/** default select hidden from UI but exists for accessability users */
const AccessibilitySelect = ({ options, value, placeholder, onChange }: AccessibilitySelectProps) => {
    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        onChange(event.target.value);
    };
    return (
        <select value={value} onChange={handleChange}>
            <option value="" disabled>
                {placeholder}
            </option>
            {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default AccessibilitySelect;
