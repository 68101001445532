const getInitialFromName = (name?: string) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase();
};

export const getInitialsFromName = (firstName?: string, lastName?: string) => {
    const firstInitial = getInitialFromName(firstName);
    const lastInitial = getInitialFromName(lastName);
    return `${firstInitial}${lastInitial}`;
};
