import { FormEvent, useState } from 'react';
import { MdOutlineArrowCircleRight } from 'react-icons/md';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Button from '../../../components/ui/Button';
import ButtonSubtle from '../../../components/ui/ButtonSubtle';
import Select from '../../../components/ui/Select';
import { kebabToTitleCase } from '../../../utils/string';
import ButtonSecondary from '../../../components/ui/ButtonSecondary';
import useNavigation from '../../../hooks/useNavigation';
import useAuth from '../../../hooks/useAuth';
import './TenantSelectionForm.css';

const TenantSelectionForm = () => {
    const [tenant, setTenant] = useState('');
    const { navigate } = useNavigation();
    const { tenants, handleSignOut } = useAuth();

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        navigate(`/dashboard?tenant=${tenant}`);
    };

    const handleChangeUser = () => {
        handleSignOut();
    };

    const options = tenants.map((tenant) => ({
        label: kebabToTitleCase(tenant),
        value: tenant,
    }));

    return (
        <form className="tenant-selection-form" onSubmit={handleSubmit}>
            <Select options={options} value={tenant} onChange={setTenant} placeholder="Select a Tenant" />
            <Button label="Continue" iconComponent={<MdOutlineArrowCircleRight size="1.2rem" />} type="submit" disabled={tenant === ''} />

            <ButtonSubtle label="Sign in to a different account" iconBefore={<FaRegQuestionCircle size="0.9rem" />} onClick={handleChangeUser} />

            <ButtonSecondary label="Switch account" onClick={handleChangeUser} />
        </form>
    );
};

export default TenantSelectionForm;
