import { ReactNode } from 'react';
import './Button.css';

interface ButtonProps {
    label: string;
    disabled?: boolean;
    iconComponent?: ReactNode;
    type?: HTMLButtonElement['type'];
    onClick?: () => void;
}

const Button = ({ label, disabled, iconComponent = null, type = 'button', onClick }: ButtonProps) => {
    return (
        <button disabled={disabled} type={type} className="button" onClick={onClick}>
            {label}
            {iconComponent}
        </button>
    );
};

export default Button;
