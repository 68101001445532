import React, { useState, useCallback, useRef } from 'react';
import UserInitialsCircle from './UserInitialsCircle';
import useAuth from '../../../hooks/useAuth';
import './UserBar.css';
import UserAvatarPopout from './UserAvatarPopout';

const UserBar: React.FC = () => {
    const { user } = useAuth();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const initialsRef = useRef<HTMLDivElement>(null);

    const toggleMenu = useCallback(() => {
        setIsMenuOpen((prevState) => !prevState);
    }, []);

    return (
        <div className="user-bar">
            <div ref={initialsRef} className="user-initials-circle" onClick={toggleMenu}>
                <UserInitialsCircle firstName={user?.firstName} lastName={user?.lastName} />
            </div>
            <UserAvatarPopout isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} initialsRef={initialsRef} />
        </div>
    );
};

export default UserBar;
