import { ReactNode } from 'react';
import LoginFormHeader from './LoginFormHeader';
import './LoginFormContainer.css';

interface LoginFormContainerProps {
    header: string;
    children: ReactNode;
}

const LoginFormContainer = ({ header, children }: LoginFormContainerProps) => {
    return (
        <div className="login-form-container">
            <LoginFormHeader />
            <h5>{header}</h5>
            {children}
            <small className="login-form-footnote">
                <strong>SMARTR®</strong> requires a tenant account - <strong>Equans Digital 2024</strong> - Contact your administrator for support
            </small>
        </div>
    );
};

export default LoginFormContainer;
