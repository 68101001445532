import equansDigitalLogo from '../../assets/equans-digital-logo.webp';
import './LoginFooter.css';

const LoginFooter = () => {
    return (
        <div className="login-footer">
            <div className="login-footer-links">
                <a href="https://www.equans.co.uk/" target="_blank" rel="noreferrer">
                    Equans UK
                </a>
                <a href="https://www.equans.co.uk/equans-digital" target="_blank" rel="noreferrer">
                    Equans Digital
                </a>
                <a href="http://smartr.build" target="_blank" rel="noreferrer">
                    Website
                </a>
                {/* Should be implemented in https://smart-buildings-ltd.atlassian.net/browse/RND-1121 */}
                <a href="" target="_blank" rel="noreferrer">
                    Privacy Policy
                </a>
            </div>

            <img src={equansDigitalLogo} alt="Equans Digital Logo" />
        </div>
    );
};

export default LoginFooter;
