import { UserModule } from '../types/module';
import { getAccessToken, getAccessTokenExpiry } from './getAccessToken';

export const getModuleUrl = (userModule: UserModule | undefined, moduleType: string): string => {
    if (!userModule) return '#';

    const accessToken = getAccessToken();
    const expiresAt = getAccessTokenExpiry();

    if (!accessToken || !expiresAt) {
        throw new Error('User is not authenticated');
    }

    try {
        const url = new URL(userModule.url);

        if ((moduleType === 'spaces' || moduleType === 'automation') && accessToken) {
            url.searchParams.append('token', accessToken);
            url.searchParams.append('expiresAt', expiresAt);
        }

        return url.toString();
    } catch (error) {
        console.error('Error constructing module URL:', error);
        return '#';
    }
};
