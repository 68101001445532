import React from 'react';
import { Link } from 'react-router-dom';
import { Module } from '../types/module';
import './ModuleButton.css';

interface ModuleButtonProps {
    module: Module;
}

const ModuleButton: React.FC<ModuleButtonProps> = ({ module }) => {
    const className = `module-button${module.disabled ? ' disabled' : ''}`;
    return (
        <div className={className}>
            <Link to={module.url} aria-label={module.name}>
                <div className="module-button-icon">{module.icon}</div>
            </Link>
            <div className="module-button-text">
                <Link to={module.url} aria-label={module.name}>
                    <p className="no-margin weight-bold inverse-color">
                        {module.name.split('®')[0]}
                        <span className="module-button-trademark">®</span>
                        {module.name.split('®')[1]}
                    </p>
                </Link>
                <span className="module-button-info-text">{module.info}</span>
            </div>
        </div>
    );
};

export default ModuleButton;
