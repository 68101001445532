interface TokenStorage {
    accessToken: {
        accessToken: string;
        expiresAt: number;
    };
}

export const getAccessToken = (): string | null => {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    if (!tokenStorage) return null;

    try {
        const parsedStorage = JSON.parse(tokenStorage) as TokenStorage;
        return parsedStorage.accessToken.accessToken;
    } catch {
        console.error('Error parsing token storage');
        return null;
    }
};

export const getAccessTokenExpiry = (): string | null => {
    const tokenStorage = localStorage.getItem('okta-token-storage');
    if (!tokenStorage) return null;

    try {
        const parsedStorage = JSON.parse(tokenStorage) as TokenStorage;
        return parsedStorage.accessToken.expiresAt.toString();
    } catch {
        console.error('Error parsing token expiry');
        return null;
    }
};
