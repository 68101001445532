import { useState } from 'react';
import { getStorageValue } from '../utils/localStorage';
import { EMAIL_STORAGE_KEY } from '../utils/consts';
import useNavigation from '../../../hooks/useNavigation';

const useLoginForm = () => {
    const initialEmail = getStorageValue(EMAIL_STORAGE_KEY, '');
    const [isEmailSet, setIsEmailSet] = useState(false);
    const [email, setEmail] = useState(initialEmail);
    const [password, setPassword] = useState('');
    const { navigate } = useNavigation();

    const handleChangeEmail = () => {
        setIsEmailSet(false);
        setEmail('');
    };

    const handleForgottenPassword = () => {
        navigate('/forgotten-password', { state: { email } });
    };

    return {
        password,
        setPassword,
        email,
        setEmail,
        handleChangeEmail,
        handleForgottenPassword,
        isEmailSet,
        setIsEmailSet,
    };
};

export default useLoginForm;
