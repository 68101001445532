import { Routes, Route } from 'react-router-dom';
import LoginRoute from './LoginRoute';
import TenantSelectionRoute from './TenantSelectionRoute';
import ErrorBoundaryRoute from './ErrorBoundaryRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import DashboardRoute from './DashboardRoute';
import SecurityProvider from '../providers/SecurityProvider';
import LoginCallbackRoute from './LoginCallbackRoute';
import ForgottenPasswordRoute from './ForgottenPasswordRoute';
import LogoutRoute from './LogoutRoute';

const AppRoutes = () => {
    return (
        <SecurityProvider>
            <Routes>
                <Route path="/" Component={LoginRoute} />
                <Route path="/forgotten-password" Component={ForgottenPasswordRoute} />
                <Route path="login/callback" Component={LoginCallbackRoute} />
                <Route path="logout" Component={LogoutRoute} />
                <Route Component={AuthenticatedRoute}>
                    <Route path="/tenants" Component={TenantSelectionRoute} />
                    <Route path="/dashboard" Component={DashboardRoute} />
                </Route>
                <Route path="/*" Component={ErrorBoundaryRoute} />
            </Routes>
        </SecurityProvider>
    );
};

export default AppRoutes;
