import './Checkbox.css';

interface CheckboxProps {
    label: string;
    name: string;
    checked: boolean;
    onChange: () => void;
}

const Checkbox = ({ label, name, checked, onChange }: CheckboxProps) => {
    return (
        <label className="checkbox-container">
            {label}
            <input type="checkbox" name={name} id={name} checked={checked} onChange={onChange} />

            <span className="checkmark">
                <span className="checkmark-inner"></span>
            </span>
        </label>
    );
};

export default Checkbox;
