import { useState } from 'react';
import './TextInput.css';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';

interface TextInputPasswordProps {
    label?: string;
    name: string;
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    tabIndex?: React.HTMLAttributes<HTMLInputElement>['tabIndex'];
}

const TextInputPassword = ({ label = 'Password', name, value, onChange, placeholder, tabIndex }: TextInputPasswordProps) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <div className="text-input">
            <label htmlFor={name}>{label}</label>
            <input
                className={`password ${value === '' ? 'placeholder-shown' : ''}`}
                type={showPassword ? 'text' : 'password'}
                id={name}
                name={name}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                placeholder={placeholder}
                tabIndex={tabIndex}
            />
            <button
                className="button-unstyled input-button"
                onClick={() => setShowPassword((prev) => !prev)}
                type="button"
                aria-label="password-visibility-button"
            >
                {!showPassword ? (
                    <MdOutlineVisibilityOff size="1.1em" title="Show password" />
                ) : (
                    <MdOutlineVisibility size="1.1em" title="Hide password" />
                )}
            </button>
        </div>
    );
};

export default TextInputPassword;
