import LoginLayout from '../../../components/layout/LoginLayout';

const TenantEmptyScreen = () => {
    return (
        <LoginLayout header="You don't have access to any tenants">
            <p className="no-margin">Please contact your administrator to apply tenant access for your user account.</p>
        </LoginLayout>
    );
};

export default TenantEmptyScreen;
