import './TextInput.css';

interface TextInputProps {
    label: string;
    name: string;
    value: string;
    placeholder?: string;
    type?: React.HTMLInputTypeAttribute;
    onChange: (value: string) => void;
    tabIndex?: React.HTMLAttributes<HTMLInputElement>['tabIndex'];
}

const TextInput = ({ label, name, type, value, onChange, placeholder, tabIndex }: TextInputProps) => {
    return (
        <div className="text-input">
            <label htmlFor={name}>{label}</label>
            <input
                type={type}
                id={name}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={(event) => onChange(event.target.value)}
                tabIndex={tabIndex}
            />
        </div>
    );
};

export default TextInput;
