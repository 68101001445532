import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import LoginLayout from '../../../components/layout/LoginLayout';

const DashboardLoadingIndicator: React.FC = () => {
    return (
        <LoginLayout header="Just a moment... Configuring workspace">
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'var(--EQ-Digital-Neutrals-600, #787c80)',
                }}
            >
                <AiOutlineInfoCircle size="1rem" style={{ marginRight: '0.8rem' }} />
                <p className="no-margin">Creating cloud environment</p>
            </div>
        </LoginLayout>
    );
};

export default DashboardLoadingIndicator;
