import React from 'react';
import './UserInitialsCircle.css';
import { getInitialsFromName } from '../utils/getInitialsFromName';

interface UserInitialsCircleProps {
    firstName?: string;
    lastName?: string;
    greyBackground?: boolean;
}

const UserInitialsCircle: React.FC<UserInitialsCircleProps> = ({ firstName, lastName, greyBackground }) => {
    const initials = getInitialsFromName(firstName, lastName);

    return (
        <div className="user-initials-circle-container">
            <div className={`user-initials-circle ${greyBackground ? 'grey' : ''}`}>
                <strong>{initials}</strong>
            </div>
            <div className="user-initials-active-circle"></div>
        </div>
    );
};

export default UserInitialsCircle;
