import Dashboard from '../../features/dashboard/components/Dashboard';
import './DashboardContainer.css';

const DashboardContainer = () => {
    return (
        <div className="dashboard-container">
            <Dashboard />;
        </div>
    );
};

export default DashboardContainer;
