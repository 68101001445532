import React from 'react';
import ModuleButton from './ModuleButton';
import { RxLayers, RxLightningBolt } from 'react-icons/rx';
import { GoInfinity } from 'react-icons/go';
import { LuLeaf } from 'react-icons/lu';
import { UserModule } from '../types/module';
import { getModuleUrl } from '../utils/moduleUrlUtil';

const ICON_SIZE = '40px';

const moduleConfig = {
    energy: {
        name: 'Energy',
        icon: <RxLightningBolt size={ICON_SIZE} />,
    },
    automation: {
        name: 'Automation',
        icon: <GoInfinity size={ICON_SIZE} />,
    },
    spaces: {
        name: 'Spaces',
        icon: <RxLayers size={ICON_SIZE} />,
    },
    infrastructure: {
        name: 'Infrastructure',
        icon: <LuLeaf size={ICON_SIZE} />,
    },
};

interface ModulesListProps {
    userModules: UserModule[];
}

const ModulesList: React.FC<ModulesListProps> = ({ userModules }) => {
    const userModuleTypes = userModules.map((module) => module.moduleType);

    return (
        <div className="modules-buttons">
            {Object.entries(moduleConfig).map(([moduleType, config]) => {
                const userModule = userModules.find((m) => m.moduleType === moduleType);
                return (
                    <ModuleButton
                        key={moduleType}
                        module={{
                            id: userModule?.id || moduleType,
                            name: `SMARTR® ${config.name}`,
                            icon: config.icon,
                            info: moduleType,
                            url: getModuleUrl(userModule, moduleType),
                            disabled: !userModuleTypes.includes(moduleType),
                        }}
                    />
                );
            })}
        </div>
    );
};

export default ModulesList;
