export enum WebfingerRel {
    /** Identity Provider is an Okta org */
    OktaIdp = 'http://openid.net/specs/connect/1.0/issuer',
    /** Identity Provider is any configurable IdP */
    ConfigurableIdp = 'okta:idp',
}

export interface WebfingerLink {
    rel: WebfingerRel;
    /** Login page for this IdP */
    href: string;
}

export interface WebfingerResponse {
    /** User's login value prefixed with okta:acct: */
    subject: string;
    links: WebfingerLink[];
}
