import { UserModule } from '../features/dashboard/types/module';
import fetcher from '../lib/fetcher';

export interface UserData {
    email: string;
    modules: UserModule[];
    oktaUserId: string;
}

export const fetchUserData = async (): Promise<UserData> => {
    return fetcher<UserData>(`${process.env.API_URL}/users/me`, {
        method: 'GET',
    });
};
