import LoginLayout from '../../components/layout/LoginLayout';

const ErrorBoundaryRoute = () => {
    return (
        <LoginLayout header="Page not found">
            <p style={{ marginTop: -10 }}>{`Sorry, the page you are looking for doesn't exist.`}</p>
        </LoginLayout>
    );
};

export default ErrorBoundaryRoute;
