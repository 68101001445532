/** Capitalises given string
 * @example
 * capitaliseWord("word") // returns "Word"
 */
export function capitaliseWord(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

/** Converts given string from kebab case to title case
 * @example
 * kebabToTitleCase("kebab-cased-string") // returns "Kebab Cased String"
 */
export function kebabToTitleCase(kebab: string): string {
    return kebab.split('-').map(capitaliseWord).join(' ');
}
