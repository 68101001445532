import LoginLayout from '../../components/layout/LoginLayout';
import useNavigation from '../../hooks/useNavigation';
import Button from '../../components/ui/Button';

const LogoutRoute = () => {
    const { navigateToLogin } = useNavigation();

    return (
        <LoginLayout header="You are now signed out">
            <Button label="Back to login" onClick={navigateToLogin} />
        </LoginLayout>
    );
};

export default LogoutRoute;
