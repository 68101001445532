import { useState } from 'react';

export type MutationFunction<FunctionData, FunctionArguments extends unknown[]> = (...args: FunctionArguments) => Promise<FunctionData | undefined>;

interface UseMutationResult<FunctionData, FunctionArguments extends unknown[]> {
    isLoading: boolean;
    error: Error | null;
    success: boolean;
    mutate: (...args: FunctionArguments) => Promise<FunctionData | undefined>;
    reset: () => void;
}

function useMutation<FunctionData, FunctionArguments extends unknown[]>(
    mutationFn: MutationFunction<FunctionData, FunctionArguments>,
): UseMutationResult<FunctionData, FunctionArguments> {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [success, setSuccess] = useState(false);

    const mutate = async (...args: FunctionArguments) => {
        reset();
        try {
            const data = await mutationFn(...args);
            setSuccess(true);
            setIsLoading(false);
            return data;
        } catch (err) {
            setIsLoading(false);
            setError(err as Error);
            return undefined;
        }
    };

    const reset = () => {
        setIsLoading(true);
        setError(null);
        setSuccess(false);
    };

    return {
        error,
        isLoading,
        mutate,
        reset,
        success,
    };
}

export default useMutation;
