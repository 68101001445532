import { useState, useEffect, useCallback, useRef } from 'react';

interface UseQueryResult<Data> {
    isLoading: boolean;
    error: Error | null;
    data: Data | null;
    refetch: () => Promise<void>;
}

function useQuery<Data>(fetchFn: () => Promise<Data>): UseQueryResult<Data> {
    const [data, setData] = useState<Data | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const isMounted = useRef(true);
    const fetchPromiseRef = useRef<Promise<void> | null>(null);

    const fetchData = useCallback((): Promise<void> => {
        if (fetchPromiseRef.current) {
            return fetchPromiseRef.current;
        }

        setIsLoading(true);
        setError(null);

        fetchPromiseRef.current = fetchFn()
            .then((result) => {
                if (isMounted.current) {
                    setData(result);
                }
            })
            .catch((err) => {
                if (isMounted.current) {
                    setError(err as Error);
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsLoading(false);
                }
                fetchPromiseRef.current = null;
            });

        return fetchPromiseRef.current;
    }, [fetchFn]);

    useEffect(() => {
        isMounted.current = true;
        void fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [fetchData]);

    return { data, isLoading, error, refetch: fetchData };
}

export default useQuery;
