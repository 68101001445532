import LoginLayout from '../../components/layout/LoginLayout';

const LoadingRoute = () => {
    return (
        <LoginLayout header="Authorising">
            <p>Please wait while we check your credentials...</p>
        </LoginLayout>
    );
};

export default LoadingRoute;
