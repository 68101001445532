import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useNavigation = () => {
    const navigate = useNavigate();

    const navigateToLogin = useCallback(() => navigate('/'), [navigate]);

    return {
        navigate,
        navigateToLogin,
    };
};

export default useNavigation;
