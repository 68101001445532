/** sets the key value from local storage with default value fallback */
export function getStorageValue(key: string, defaultValue: string) {
    const saved = localStorage.getItem(key);
    return saved ? (JSON.parse(saved) as string) : defaultValue;
}

/** sets the key value into local storage */
export function setStorageValue(key: string, value: string) {
    localStorage.setItem(key, JSON.stringify(value));
}

/** deletes the key value in local storage */
export function deleteStorageValue(key: string) {
    localStorage.removeItem(key);
}
