import './ButtonSecondary.css';

interface ButtonSecondaryProps {
    label: string;
    disabled?: boolean;
    onClick: () => void;
}

const ButtonSecondary = ({ label, disabled, onClick }: ButtonSecondaryProps) => {
    return (
        <button disabled={disabled} type="button" className="button button-secondary" onClick={onClick}>
            {label}
        </button>
    );
};

export default ButtonSecondary;
