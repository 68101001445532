import { useCallback } from 'react';
import { fetchUserData, UserData } from '../../../services/userService';
import useQuery from '../../../hooks/useQuery';

interface UseUserDataResult {
    userData: UserData | null;
    isLoading: boolean;
    error: Error | null;
    refetch: () => Promise<void>;
}

const useUserData = (): UseUserDataResult => {
    const fetchUserDataCallback = useCallback(() => fetchUserData(), []);
    const { data, isLoading, error, refetch } = useQuery<UserData>(fetchUserDataCallback);

    return {
        userData: data,
        isLoading,
        error,
        refetch,
    };
};

export default useUserData;
