import { ReactNode } from 'react';
import './ButtonSubtle.css';

interface ButtonSubtleProps {
    label: string;
    iconBefore?: ReactNode;
    onClick?: () => void;
}

const ButtonSubtle = ({ label, iconBefore = null, onClick }: ButtonSubtleProps) => {
    return (
        <button className="button-subtle" type="button" onClick={onClick}>
            {iconBefore}
            {label}
        </button>
    );
};

export default ButtonSubtle;
