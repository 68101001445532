import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import UserInitialsCircle from './UserInitialsCircle';
import useAuth from '../../../hooks/useAuth';
import { RxExit } from 'react-icons/rx';
import { TbUsers } from 'react-icons/tb';
import './UserAvatarPopout.css';

interface UserAvatarPopoutProps {
    isMenuOpen: boolean;
    setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
    initialsRef: React.RefObject<HTMLDivElement>;
}

const UserAvatarPopout: React.FC<UserAvatarPopoutProps> = ({ isMenuOpen, setIsMenuOpen, initialsRef }) => {
    const navigate = useNavigate();
    const { handleSignOut, tenants, user } = useAuth();
    const popoutRef = useRef<HTMLDivElement>(null);

    const handleTenantChange = () => {
        navigate('/tenants');
    };

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (
                isMenuOpen &&
                popoutRef.current &&
                !popoutRef.current.contains(event.target as Node) &&
                initialsRef.current &&
                !initialsRef.current.contains(event.target as Node)
            ) {
                setIsMenuOpen(false);
            }
        },
        [isMenuOpen, setIsMenuOpen, initialsRef],
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <div ref={popoutRef} className={`user-popout ${isMenuOpen ? 'visible' : ''}`}>
            <div className="user-popout-info">
                <UserInitialsCircle firstName={user?.firstName} lastName={user?.lastName} greyBackground={true} />
                <div className="user-popout-details">
                    <span className="user-popout-name">
                        {user?.firstName} {user?.lastName}
                    </span>
                    <span className="user-popout-email">{user?.email}</span>
                </div>
            </div>
            <p className="user-popout-subscription">
                Your workspace is on the{' '}
                <strong>
                    SMARTR<span className="user-popout-trademark">®</span> Cloud
                </strong>{' '}
                enterprise subscription.
            </p>
            <hr className="user-popout-divider" />

            {tenants?.length > 1 && (
                <div className="user-popout-menu-item" onClick={handleTenantChange}>
                    Change Tenant
                    <TbUsers size={18} />
                </div>
            )}
            <hr className="user-popout-divider" />

            <div
                className="user-popout-menu-item"
                onClick={() => {
                    handleSignOut();
                }}
            >
                Log Out
                <RxExit size={18} />
            </div>
        </div>
    );
};

export default UserAvatarPopout;
