import { ReactNode } from 'react';
import LoginFooter from './LoginFooter';
import backgroundImage from '../../assets/login-page-background.webp';
import LoginFormContainer from './LoginFormContainer';
import './LoginLayout.css';

interface LoginLayoutProps {
    children: ReactNode;
    header: string;
}

const GRADIENT_STYLE = 'to right, rgb(0,22,36,100), rgb(0,22,36,100) 62%, rgb(0,22,36,0))';

const LoginLayout = ({ children, header }: LoginLayoutProps) => {
    return (
        <div
            style={{
                backgroundImage: `linear-gradient(${GRADIENT_STYLE}, url(${backgroundImage})`,
            }}
            className="login-page"
        >
            <div className="login-content">
                <LoginFormContainer header={header}>{children}</LoginFormContainer>
            </div>
            <LoginFooter />
        </div>
    );
};

export default LoginLayout;
