import { captureException } from './sentry';

type LogArgument = string | number | boolean | object | null | undefined;

export default {
    /** only shows in local environments */
    debug: (...logs: LogArgument[]): void => {
        if (process.env.NODE_ENV === 'development') {
            console.debug(...logs);
        }
    },
    info: (...logs: LogArgument[]): void => {
        console.info(...logs);
    },
    warn: (...logs: LogArgument[]): void => {
        console.warn(...logs);
    },
    /** logs an error in the console and external logging */
    error: (...logs: LogArgument[]): void => {
        captureException(logs);
        console.error(...logs);
    },
};
