import ModulesList from './ModulesList';
import './ModulesSection.css';
import useUserData from '../hooks/useUserData';
import { UserData } from '../../../services/userService';

interface ModulesSectionProps {
    userData: UserData | null;
    error: Error | null;
}

const ModulesSection: React.FC<ModulesSectionProps> = () => {
    const { userData, error } = useUserData();

    return (
        <div className="modules-container">
            <div className="modules-content">
                <h3 className="modules-header">
                    SMARTR<span className="modules-header-trademark">®</span> <span className="weight-light">Product Ecosystem</span>
                </h3>
                <hr className="divider" />
                <h6>Modules</h6>
                {error && <h3 className="centered">Error: {error.message}</h3>}
                {!error && userData ? <ModulesList userModules={userData.modules} /> : null}
            </div>
        </div>
    );
};

export default ModulesSection;
